<!--
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2023-01-12 16:21:41
-->
<template>
  <div class="contain" ref="box">
    <!-- 用户头 -->
    <personInfo></personInfo>
    <Sidebar
      :showCar="true"
      @showCar="showCar"
      @showShare="showShare"
      ref="sideBar"
    ></Sidebar>
    <!-- 面包屑 -->
    <div class="mianbao">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{
          $t("w_0114")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item
          v-if="specialArea != 18 && specialArea != 19"
          :to="{ path: '/shoppingMall?id=' + specialArea }"
          >{{ labelName }}</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else :to="{ path: '/spaceMallList' }">{{
          labelName
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("w_0115") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="details_t">
      <!-- 轮播图 -->
      <Swiper :mainImgUrls="mainImgUrl" :imgUrlLists="imgUrlList"></Swiper>
      <!-- 商品信息 -->
      <div class="details_c">
        <div class="dis_flex">
          <div class="qzbq" v-if="goodDetail.prefixLabelTarget">
            [{{ goodDetail.prefixLabelTarget.label }}]
          </div>
          <div class="c_tit1">
            {{ goodDetail.waresName }}
          </div>
        </div>

        <div class="c_hui">{{ goodDetail.sellingPoints }}</div>

        <div class="c_bai">
          <div class="dis_flex jusc" v-if="goodDetail.preSaleStatus == 1">
            <div class="dis_flex">
              <img src="@/assets/images/clock.png" alt="" />
              <div>{{ $t("w_0116") }}</div>
            </div>
            <div class="dis_flex jusc_r">
              <div>{{ $t("CK_KS_11") }}:</div>
              <div class="jusc_ri">{{ countDown }}</div>
            </div>
          </div>
          <div class="c_bai_b">
            <div class="dis_flex just">
              <div class="dis_flex">
                <div class="tit5">
                  {{ userInfo.currencyIcon
                  }}{{ goodDetail.goodsPrice | toThousandthAndKeepDecimal }}
                </div>
                <!-- <div class="tit6"   v-show="
                    goodDetail.specialArea == 31">
                  统一零售价:{{ userInfo.currencyIcon
                  }}{{ goodDetail.retailPrice | toThousandthAndKeepDecimal }}
                </div> -->
              </div>
              <div class="tit7">{{ $t("w_0117") }}</div>
            </div>
            <div class="dis_flex just m10">
              <div class="tit4 dis_flex">
                <div
                  v-show="
                    goodDetail.specialArea != 13 && goodDetail.specialArea != 31&& goodDetail.specialArea !=10
                  "
                  style="margin-right: 20px"
                >
                  {{ $t("ENU_TOTAL_V_1") }}:{{
                   ( goodDetail.goodsAchive | toThousandthAndKeepDecimal) || 0
                  }}
                </div>
                <div
                  v-show="
                    goodDetail.specialArea == 31 && userInfo.isMakerSpace == 0
                  "
                  style="margin-right: 20px"
                >
                  {{ $t("ENU_TOTAL_V_1") }}:{{
                    goodDetail.goodsAchive | toThousandthAndKeepDecimal
                  }}
                </div>
                <div
                  v-show="goodDetail.specialArea == 10"
                >
                  {{ $t("w_0118") }}:{{
                    goodDetail.deductMoney | numberToCurrency
                  }}
                </div>
                <div v-show="goodDetail.specialArea == 13">
                  BV:{{
                    goodDetail.goodsAssAchive | toThousandthAndKeepDecimal
                  }}
                </div>
              </div>
              <div class="tit5">{{ goodDetail.sales | seles }}</div>
            </div>
            <div class="dis_flex just m10">
              <div class="dis_flex" v-if="goodDetail.giftNames">
                <div class="tit8">{{ $t("ENU_SALE_T_0") }}：</div>
                <div class="tit9">{{ goodDetail.giftNames }}</div>
              </div>
              <div v-else></div>
            </div>
          </div>
        </div>
        <!-- 规格 -->
        <!-- <div class="gui">
              <div class="gui_l">{{ $t('w_0107') }}</div>
              <div class="gui_r">
                <div class="gui_all"
                     v-for="item,index in goodDetail.productParams"
                     :key="item.productId">
                  <div class="gui_t">{{ item.productName }} <span class="coloNum">*{{ item.quantity }}</span></div>
                  <div class="dis_flex1">
                    <div :class="['gui_i',item.pkWaresSpecsSku == ctem.pkSkuId?'gui_a':'']"
                         v-for="ctem,cndex in item.waresItemsParamsList"
                         :key="ctem.pkSkuId"
                         @click="tapSku(index,ctem)">
                      <img :src="ctem.specCover"
                           alt="">
                      <div>{{ ctem.specsName}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
        <div class="gui">
          <div
            v-for="(item, index) in goodDetail.productParams"
            :key="item.productId"
            v-if="item.waresItemsParamsList.length > 0"
          >
            <div class="gui_t">
              <div class="gui_th">{{ $t("w_0107") }}:</div>
              <div class="gui_y">
                <div class="gui_tt">{{ item.productName }}</div>
                <div class="gui_yi">{{ $t("w_0121") }}:</div>
                <span class="coloNum">{{ item.quantity }}</span>
                <div class="gui_yi">{{ $t("w_0119") }}:</div>
                <span class="coloNum">{{ item.quantitySel }}</span>
              </div>
            </div>
            <div class="gui_b">
              <div class="gui_bt">{{ $t("w_0120") }}:</div>
              <div class="gui_bb">
                <div
                  v-for="(ctem, cndex) in item.waresItemsParamsList"
                  :key="ctem.pkSkuId"
                  class="gui_i"
                >
                  <div class="gui_ia">
                    <el-popover placement="top-start" trigger="hover">
                      <div>{{ ctem.specsName }}</div>
                      <div
                        slot="reference"
                        v-show="ctem.specsNameDian.length > 17"
                        @click.stop="tapSku(index, cndex, ctem.quantity)"
                        :class="['gui_ii', ctem.checked ? 'gui_a' : '']"
                      >
                        <img :src="ctem.specCover" alt="" />
                        <div class="gui_iit">{{ ctem.specsNameDian }}</div>
                      </div>
                    </el-popover>
                    <div
                      v-show="ctem.specsNameDian.length <= 17"
                      @click.stop="tapSku(index, cndex, ctem.quantity)"
                      :class="['gui_ii', ctem.checked ? 'gui_a' : '']"
                    >
                      <img :src="ctem.specCover" alt="" />
                      <div class="gui_iit">{{ ctem.specsNameDian }}</div>
                    </div>
                  </div>
                  <div class="jbq" v-show="ctem.checked">
                    <el-input-number
                      @change="getNum(index, cndex, $event)"
                      v-model="ctem.quantity"
                      :max="ctem.maxNum"
                      :min="0"
                    >
                    </el-input-number>
                  </div>
                  <div class="jbq" v-show="!ctem.checked"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 数量 -->
        <div
          class="dis_flex"
          v-if="userInfo.registerShare == 0 || userInfo.registerFans == 0"
        >
          <div>{{ $t("MY_TH_2") }}：</div>
          <el-input-number v-model="num" :min="1"></el-input-number>
        </div>
        <div
          class="addCar"
          v-if="
            (specialArea == 31 && userInfo.isMakerSpace == 0) ||
            specialArea != 31
          "
          @click="toAddCar"
        >
          {{ $t("N_I_192") }}
        </div>
        <!-- <div class="addCar" v-else @click="goBuy">{{ $t('w_0085') }}</div> -->
        <!-- {{ $t('w_0122') }} -->
        <div class="dis_flex fw">
          <div>{{ $t("w_0122") }}:</div>
          <div
            class="fw_i"
            v-for="item in goodDetail.ensureLabelList"
            :key="item.pkId"
          >
            <img src="@/assets/images/duihao.png" alt="" />
            <div>{{ item.label }}</div>
          </div>
        </div>
      </div>
      <!-- 推荐 -->
      <!-- 商品列表 -->
      <div class="goodList">
        <div class="tited">{{ $t("w_0123") }}</div>
        <div
          class="good_i"
          v-for="item in goodList"
          :key="item.waresCode"
          @click="goDetails(item)"
        >
          <img class="goodImg" :src="item.cover1" alt="" />
          <div class="goodBtm">
            <div class="disFlex">
              <div class="tit5">
                {{ userInfo.currencyIcon
                }}{{ item.waresPrice | toThousandthAndKeepDecimal }}
              </div>
              <div class="tit2"></div>
            </div>
            <div class="disFlex">
              <div class="tit3">{{ item.waresName }}</div>
            </div>
            <div class="disFlex">
              <div class="tit4">
                {{ $t("CK_KS_102") }}:{{ item.sales | seles }}
              </div>
              <div class="tit4">
                {{ $t("ENU_TOTAL_V_1") }}:{{
                  item.waresAchieve | toThousandthAndKeepDecimal
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="menuList">
      <div
        :class="['menu_i', isMenu == 0 ? 'actMenu' : '']"
        @click="isMenu = 0"
      >
        {{ $t("w_0124") }}
      </div>
      <div
        :class="['menu_i', isMenu == 1 ? 'actMenu' : '']"
        @click="isMenu = 1"
      >
        {{ $t("w_0125") }}
      </div>
    </div>
    <div class="xian"></div>
    <div class="allDetail">
      <div
        v-show="isMenu == 0"
        class="allDetail_i"
        v-html="goodDetail.details"
      ></div>
      <div
        v-show="isMenu == 1"
        class="allDetail_i"
        v-html="goodDetail.afterGuarantee"
      ></div>
    </div>

    <selSpaceGoods ref="selSpaceGoods" @getCar="getCatLength"></selSpaceGoods>
  </div>
</template>

<script>
import personInfo from "@/components/personInfo.vue";
import Swiper from "@/components/Swiper.vue";
import Sidebar from "@/components/Sidebar.vue";
import * as api from "@/api/goods.js";
import { memLevel } from "@/api/register.js";
import { mapGetters } from "vuex";
import selSpaceGoods from "@/components/selSpaceGoods.vue";

export default {
  components: {
    personInfo,
    Swiper,
    Sidebar,
    selSpaceGoods,
  },
  data() {
    return {
      isMenu: 0,
      specialArea: "",
      waresCode: "",
      goodList: [
        {
          waresCode: "1",
          cover1:
            "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
          waresPrice: "1980.00",
          waresName: "海之圣超饱和富氢水杯（粉色），你值得拥有",
        },
      ],
      goodDetail: {
        num: 1,
        prefixLabelTarget: {},
      },
      isClick: 0,
      num: 1,
      mainImgUrl: "",
      imgUrlList: [],
      labelName: "",
      countDown: "",
      pkCountry: "",
      moneySymbol: "",
      zoneList: [
        {
          label: this.$t("N_I_1"),
          value: 100,
          name: "share",
          children: [
            {
              label: this.$t("N_I_2"),
              value: 7,
              name: "hiFans",
            },
            {
              label: this.$t("N_I_3"),
              value: 21,
              name: "haiFans",
            },
          ],
        },
        {
          label: this.$t("ENU_SPECIAL_A_1"),
          value: 1,
          name: "regiest",
          children: [
            {
              label: this.$t("ENU_MENU_321"),
              value: 1,
              name: "regiestArea",
            },
            {
              label: this.$t("ENU_MENU_335"),
              value: 24,
              name: "ezLearnReg",
            },
          ],
        },
        {
          label: this.$t("ENU_MENU_320"),
          value: 2,
          name: "upgrade",
          children: [
            {
              label: this.$t("ENU_MENU_321"),
              value: 2,
              name: "upgradeArea",
            },
            {
              label: this.$t("ENU_MENU_335"),
              value: 25,
              name: "ezLearnUp",
            },
            {
              label: this.$t("ENU_SPECIAL_A_7"),
              value: 27,
              name: "haiFunUpd",
            },
          ],
        },
        {
          label: this.$t("ENU_MENU_330"),
          value: 3,
          name: "repurchase",
          children: [
            {
              label: this.$t("ENU_MENU_321"),
              value: 3,
              name: "self",
            },
            {
              label: this.$t("N_I_4"),
              value: 21,
              name: "haiFans",
            },
            {
              label: this.$t("ENU_SPECIAL_A_22"),
              value: 22,
              name: "cooperation",
            },
            {
              label: this.$t("ENU_MENU_335"),
              value: 26,
              name: "ezLearnRep",
            },
            {
              label: this.$t("ENU_MENU_334"),
              value: 14,
              name: "live",
            },
            {
              label: this.$t("ENU_ORDER_T_12"),
              value: 12,
              name: "gongju",
            },
          ],
        },
        {
          label: this.$t("ENU_MENU_340"),
          value: 13,
          children: [],
          name: "welfare",
        },
        // {
        //   label: '积分专区',
        //   value: 11,
        //   children: [],
        //   name: 'integral',
        // },
        {
          label: this.$t("ENU_MENU_360"),
          value: 10,
          children: [],
          name: "rescission",
        },
      ],
    };
  },
  filters: {
    seles(value) {
      if (value > 999) {
        return 999 + "+";
      } else {
        return value;
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(n) {
        this.pkCountry = parseInt(localStorage.getItem("pkCountry"));
        this.specialArea = n.query.specialArea;

        this.waresCode = n.query.waresCode;

        this.zoneList.forEach((ctem) => {
          if (ctem.value == this.specialArea) {
            this.labelName = ctem.label;
          }
          if (ctem.children.length > 0) {
            ctem.children.forEach((stem) => {
              if (stem.value == this.specialArea) {
                this.labelName = stem.label;
              }
            });
          }
        });
        // this.labelName = n.query.label
        this.getDetails();
        this.$store.dispatch("SpecialQuantity", {
          specialArea: this.specialArea,
          pkCountry: this.pkCountry,
        });
        this.getRecGoods();
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.pkCountry = parseInt(localStorage.getItem("pkCountry"));
    // this.specialArea = this.$route.query.specialArea
    // this.waresCode = this.$route.query.waresCode
    // this.labelName = this.$route.query.label
    // this.getDetails()
    // this.getRecGoods()
    // this.getMoneySymbol()
  },
  methods: {
    showShare() {
      this.$refs.sideBar.getShare(this.specialArea);
    },
    getMoneySymbol() {
      if (this.specialArea == 1) {
        api
          .getCurrency({
            pkCountry: this.pkCountry,
          })
          .then((res) => {
            this.moneySymbol = res.data.symbol;
          });
      } else {
        this.moneySymbol = this.userInfo.currencyIcon;
      }
    },
    goDetails(item) {
      let that = this;
      this.$router.replace({
        path: "/goodsDetails",
        query: {
          waresCode: item.waresCode,
          specialArea: this.specialArea,
          label: this.labelName,
        },
      });
    },
    getRecGoods() {
      api
        .recommend_wares({
          specialArea: this.specialArea,
          pkAreaClassify: "",
          pkCountry: this.pkCountry || "",
        })
        .then((res) => {
          if (res.code == 200) {
            this.goodList = res.data;
          }
        });
    },
    showCar() {
      this.$refs.sideBar.getCarList(this.specialArea);
    },
    toAddCar() {
      this.goodDetail.productParams.forEach((item) => {
        item.num = 0;
        item.waresItemsParamsList.forEach((ctem) => {
          item.num += ctem.quantity;
        });
      });
      let allNum = 0;
      let selNum = 0;
      this.goodDetail.productParams.forEach((item) => {
        allNum += item.quantity;
        selNum += item.num;
      });
      if (allNum != selNum) {
        this.$message({
          message: this.$t("w_0126"),
          type: "warning",
        });
        return false;
      }
      let carList = {
        specialArea: this.goodDetail.specialArea,
        number: this.num,
        waresCode: this.goodDetail.waresCode,
        productGroup: [],
        pkCountry: this.pkCountry || "",
      };
      this.goodDetail.productParams.forEach((item) => {
        item.waresItemsParamsList.forEach((ctem) => {
          if (ctem.quantity != 0) {
            carList.productGroup.push({
              pkProduct: ctem.pkProduct,
              pkSkuId: ctem.pkSkuId,
              quantity: ctem.quantity,
            });
          }
        });
      });
      if (this.goodDetail.isMakerGift == 2) {
        this.$refs.selSpaceGoods.getData(carList);
      } else {
        api.addShopping(carList).then((res) => {
          this.$store.dispatch("SpecialQuantity", {
            specialArea: this.goodDetail.specialArea,
            pkCountry: this.pkCountry,
          });
          this.$message({
            message: this.$t("w_0036"),
            type: "success",
          });
        });
      }
    },
    getCatLength() {
      this.$store.dispatch("SpecialQuantity", {
        specialArea: this.goodDetail.specialArea,
        pkCountry: this.pkCountry,
      });
    },
    goBuy() {
      this.goodDetail.productParams.forEach((item) => {
        item.num = 0;
        item.waresItemsParamsList.forEach((ctem) => {
          item.num += ctem.quantity;
        });
      });
      let allNum = 0;
      let selNum = 0;
      this.goodDetail.productParams.forEach((item) => {
        allNum += item.quantity;
        selNum += item.num;
      });
      if (allNum != selNum) {
        this.$message({
          message: this.$t("w_0126"),
          type: "warning",
        });
        return false;
      }
      let waresInfoParam = {
        number: this.num,
        waresCode: this.goodDetail.waresCode,
        pkWares: this.goodDetail.pkWares,
        waresSpecsSku: [],
        pkCountry: this.pkCountry || "",
      };
      this.goodDetail.productParams.forEach((item) => {
        item.waresItemsParamsList.forEach((ctem) => {
          if (ctem.quantity != 0) {
            waresInfoParam.waresSpecsSku.push({
              pkWaresSpecsSku: ctem.pkSkuId,
              quantity: ctem.quantity,
            });
          }
        });
      });
      this.$router.push({
        path: "shareFansConfirm",
        query: {
          allData: JSON.stringify(waresInfoParam),
        },
      });
    },
    calPrice() {
      if (this.userInfo.pkCountry == 1) {
        this.goodDetail.productParams.forEach((item) => {
          item.waresItemsParamsList.forEach((ctem) => {
            ctem.priceTotal = ctem.price * ctem.quantity;
            ctem.vipPriceTotal = ctem.vipPrice * ctem.quantity;
            ctem.retailPriceTotal = ctem.retailPrice * ctem.quantity;
            ctem.assAchieveTotal = ctem.assAchieve * ctem.quantity;
            ctem.achieveTotal =
              ctem.achieve * ctem.quantity * this.goodDetail.inExchangeRate;
          });
        });
      } else {
        this.goodDetail.productParams.forEach((item) => {
          item.waresItemsParamsList.forEach((ctem) => {
            ctem.priceTotal = ctem.price * ctem.quantity;
            ctem.achieveTotal = ctem.achieve * ctem.quantity;
            ctem.assAchieveTotal = ctem.assAchieve * ctem.quantity;
          });
        });
      }
      let price = 0;
      let vipPrice = 0;
      let retailPrice = 0;
      let achieve = 0;
      let assAchieve = 0;
      if (this.specialArea == 31) {
        this.goodDetail.productParams.forEach((item) => {
          item.waresItemsParamsList.forEach((ctem) => {
            if (ctem.checked) {
              price += ctem.priceTotal;
              vipPrice += ctem.vipPriceTotal;
              retailPrice += ctem.retailPriceTotal;
              achieve += ctem.achieveTotal;
              assAchieve += ctem.assAchieveTotal;
            }
          });
        });
        this.goodDetail.productParams.forEach((item) => {
          item.quantitySel = 0;
          item.waresItemsParamsList.forEach((ctem) => {
            item.quantitySel += ctem.quantity;
          });
        });
        if (this.userInfo.isMakerSpace == 0) {
          this.goodDetail.goodsPrice = price;
        } else {
          this.goodDetail.goodsPrice = vipPrice;
        }
        this.goodDetail.retailPrice = retailPrice;
        this.goodDetail.goodsAchive = parseFloat(achieve).toFixed(2);
        this.goodDetail.goodsAssAchive = parseFloat(assAchieve).toFixed(2);
      } else {
        this.goodDetail.productParams.forEach((item) => {
          item.waresItemsParamsList.forEach((ctem) => {
            if (ctem.checked) {
              price += ctem.priceTotal;
              achieve += ctem.achieveTotal;
              assAchieve += ctem.assAchieveTotal;
            }
          });
        });
        this.goodDetail.productParams.forEach((item) => {
          item.quantitySel = 0;
          item.waresItemsParamsList.forEach((ctem) => {
            item.quantitySel += ctem.quantity;
          });
        });

        this.goodDetail.goodsPrice = price;
        this.goodDetail.goodsAchive = parseFloat(achieve).toFixed(2);
        this.goodDetail.goodsAssAchive = parseFloat(assAchieve).toFixed(2);
      }
    },
    getNum(index, cndex, quantity) {
      // 数量为0，关闭高亮
      if (quantity == 0) {
        this.$set(
          this.goodDetail.productParams[index].waresItemsParamsList[cndex],
          "checked",
          false
        );
      } else {
        this.$set(
          this.goodDetail.productParams[index].waresItemsParamsList[cndex],
          "checked",
          true
        );
      }
      let ggNum = 0;
      this.goodDetail.productParams[index].waresItemsParamsList.forEach(
        (item) => {
          ggNum += item.quantity;
        }
      );
      if (this.goodDetail.productParams[index].quantity - quantity >= 0) {
        this.goodDetail.productParams[index].waresItemsParamsList.forEach(
          (stem, sndex) => {
            if (sndex != cndex) {
              this.$set(
                stem,
                "maxNum",
                this.goodDetail.productParams[index].quantity -
                  (ggNum - stem.quantity)
              );
            }
          }
        );
      }
      this.calPrice();
    },
    tapSku(index, cndex, quantity) {
      if (quantity > 0) {
        this.$set(
          this.goodDetail.productParams[index].waresItemsParamsList[cndex],
          "checked",
          false
        );
        this.$set(
          this.goodDetail.productParams[index].waresItemsParamsList[cndex],
          "quantity",
          0
        );
        let ggNum = 0;
        this.goodDetail.productParams[index].waresItemsParamsList.forEach(
          (item) => {
            ggNum += item.quantity;
          }
        );
        this.goodDetail.productParams[index].quantitySel = ggNum;
        this.goodDetail.productParams[index].waresItemsParamsList.forEach(
          (stem, sndex) => {
            if (sndex == cndex) {
            } else {
              this.$set(
                stem,
                "maxNum",
                this.goodDetail.productParams[index].quantity -
                  (ggNum - stem.quantity)
              );
            }
          }
        );
      } else {
        let ggNum = 0;
        this.goodDetail.productParams[index].waresItemsParamsList.forEach(
          (item) => {
            ggNum += item.quantity;
          }
        );
        this.goodDetail.productParams[index].quantitySel = ggNum + 1;
        let tidai = quantity;
        if (ggNum < this.goodDetail.productParams[index].quantity) {
          this.$set(
            this.goodDetail.productParams[index].waresItemsParamsList[cndex],
            "checked",
            true
          );
          this.$set(
            this.goodDetail.productParams[index].waresItemsParamsList[cndex],
            "quantity",
            1
          );
        }
        if (this.goodDetail.productParams[index].quantity - (ggNum + 1) >= 0) {
          this.goodDetail.productParams[index].waresItemsParamsList.forEach(
            (stem, sndex) => {
              if (sndex == cndex) {
                this.$set(
                  stem,
                  "maxNum",
                  this.goodDetail.productParams[index].quantity - ggNum
                );
              } else {
                this.$set(
                  stem,
                  "maxNum",
                  this.goodDetail.productParams[index].quantity -
                    (ggNum + 1 - stem.quantity)
                );
              }
            }
          );
        }
      }
      this.calPrice();
      this.$forceUpdate();
    },
    getDetails() {
      api
        .waresDetail({
          specialArea: this.specialArea,
          waresCode: this.waresCode,
          pkCountry: this.pkCountry || "",
        })
        .then((res) => {
          this.goodDetail = res.data;
          let sl = this.goodDetail.sales + "";
          if (sl >= 10000) {
            sl = sl.substring(0, sl.length - 4) + this.$t("S_C_58") + "+";
          }
          this.goodDetail.sales = sl;
          this.mainImgUrl = res.data.videoUrl;
          this.imgUrlList = [
            res.data.cover,
            res.data.cover1,
            res.data.cover2,
            res.data.cover3,
            res.data.cover4,
            res.data.cover5,
            res.data.cover6,
          ];

          this.imgUrlList = this.imgUrlList.filter(
            (item) => item && item.trim()
          );
          this.goodDetail.productParams.forEach((item, index) => {
            item.quantitySel = item.quantity;
            item.waresItemsParamsList.forEach((ctem, cndex) => {
              ctem.checked = false;
              ctem.quantity = 0;
              ctem.maxNum = 0;
              if (ctem.specsName.length > 17) {
                ctem.specsNameDian = ctem.specsName.substring(0, 17) + "...";
              } else {
                ctem.specsNameDian = ctem.specsName;
              }
            });
          });
          // 赋初值
          this.goodDetail.productParams.forEach((item, index) => {
            item.waresItemsParamsList.forEach((ctem, cndex) => {
              if (cndex == 0) {
                this.$set(ctem, "checked", true);
                this.$set(ctem, "quantity", item.quantity);
                this.$set(ctem, "maxNum", item.quantity);
                // 初始总价格业绩
                this.$set(item, "priceTotal", ctem.priceTotal);
                this.$set(item, "vipPriceTotal", ctem.vipPriceTotal);
                this.$set(item, "retailPriceTotal", ctem.retailPriceTotal);
                this.$set(item, "achieveTotal", ctem.achieveTotal);
                this.$set(item, "assAchieveTotal", ctem.assAchieveTotal);
              }
            });
          });
          this.$set(this.goodDetail, "goodsPrice", 0);
          this.$set(this.goodDetail, "retailPrice", 0);
          this.$set(this.goodDetail, "goodsAchive", 0);
          this.$set(this.goodDetail, "goodsAssAchive", 0);

          this.goodDetail.productParams.forEach((item) => {
            if (this.specialArea == 31) {
              if (this.userInfo.isMakerSpace == 0) {
                this.goodDetail.goodsPrice += parseFloat(item.priceTotal);
                this.goodDetail.retailPrice += parseFloat(
                  item.retailPriceTotal
                );
                this.goodDetail.goodsAchive += parseFloat(item.achieveTotal);
                this.goodDetail.goodsAssAchive += parseFloat(
                  item.assAchieveTotal
                );
              } else {
                this.goodDetail.goodsPrice += parseFloat(item.vipPriceTotal);
                this.goodDetail.retailPrice += parseFloat(
                  item.retailPriceTotal
                );
                this.goodDetail.goodsAchive += parseFloat(item.achieveTotal);
                this.goodDetail.goodsAssAchive += parseFloat(
                  item.assAchieveTotal
                );
              }
            } else {
              this.goodDetail.goodsPrice += parseFloat(item.priceTotal);
              this.goodDetail.goodsAchive += parseFloat(item.achieveTotal);
              this.goodDetail.goodsAssAchive += parseFloat(
                item.assAchieveTotal
              );
            }
          });

          // this.tapSku(index, 0, item.quantity)
          // this.goodDetail.productParams.forEach((item, index) => {
          //   this.tapSku(index, item.waresItemsParamsList[0])
          // })
          this.downTime();
          if (this.specialArea == 31) {
          this.$refs.sideBar.getSpecial(31);
    }
        });
    },
    downTime() {
      let that = this;
      this.clockTime = setInterval(() => {
        if (
          that.goodDetail.deliveryTimeMs &&
          that.goodDetail.deliveryTimeMs > 0
        ) {
          that.countDown = this.getTime(this.goodDetail.deliveryTimeMs--);
        } else {
          that.countDown = "0天00时00分00秒";
        }
      }, 1000);
    },
    getTime(time) {
      // 转换为式分秒
      let d = parseInt(time / 60 / 60 / 24); //计算剩余天数\
      d = d < 10 ? "0" + d : d;
      let h = parseInt((time / 60 / 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(time % 60);
      s = s < 10 ? "0" + s : s;
      // 作为返回值返回
      return d + "天" + h + "小时" + m + "分" + s + "秒";
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  background: rgba(249, 249, 249, 1);
  min-height: 100vh;
}

.mianbao {
  padding: 0 60px;
  margin-top: 30px;
}

.details_t {
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  margin-top: 40px;
  .details_c {
    flex: 1;
    margin: 0 60px 0 40px;
    .c_tit1 {
      font-size: 22px;
      font-weight: 600;
      color: #333333;
    }
    .c_hui {
      padding: 22px 20px;
      background: #eeeeee;
      border-radius: 10px 10px 10px 10px;
      font-size: 18px;
      color: #333333;
      margin-top: 20px;
    }
    .c_bai {
      // padding: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
      border-radius: 10px 10px 10px 10px; // margin-top: 20px;
      .c_bai_b {
        padding: 20px;
      }
    }
  }
}

.dis_flex {
  display: flex;
  align-items: center;
}

.dis_flex1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.just {
  justify-content: space-between;
}

.m10 {
  margin-top: 10px;
}

.goodList {
  .tited {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
  } // display: flex;
  // align-items: center;
  // justify-content: space-around;
  // flex-wrap: wrap;
  .good_i {
    margin-bottom: 20px;
    .goodBtm {
      padding: 10px 20px 10px 20px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(172, 172, 172, 0.5);
      border-radius: 0px 0px 10px 10px;
      margin-top: -5px;
    }
  }
  .goodImg {
    width: 318px;
    height: 318px;
    border-radius: 10px 10px 0px 0px;
  }
}

.disFlex {
  width: 278px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.gui {
  display: flex;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 20px;
  flex-direction: column;
  height: 350px;
  overflow-y: auto;
  .gui_t {
    display: flex;
    align-items: center;
    .gui_th {
      font-size: 18px;
      color: #333;
      font-weight: 600;
    }
    .gui_y {
      display: flex;
      align-items: center;
    }
    .gui_tt {
      margin-left: 10px;
      font-size: 16px;
      color: #333;
      font-weight: 600;
    }
    .gui_yi {
      color: #999;
      font-size: 16px;
    }
    .coloNum {
      color: #b42b2a;
      font-weight: 600;
      margin: 0 10px;
      margin-top: 3px;
    }
  }
  .gui_b {
    display: flex;
    .gui_bt {
      line-height: 70px;
      font-size: 18px;
      color: #333;
      font-weight: 600;
    }
    .gui_bb {
      margin-left: 10px;
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0 20px 10px;
    }
    .gui_i {
      // position: relative;
      display: flex;
      width: 50%;
      align-items: center;
      margin: 0px 0px 10px 0;
    }
    .gui_ia {
      flex: 1;
    }
    .gui_ii {
      display: flex;
      align-items: center;
      background: #ededed;
      border-radius: 10px;
      font-size: 14px;
      color: #333333;
      padding: 5px;
      img {
        width: 40px;
        height: 40px; // margin-right: 10px;
        border-radius: 10px;
      }
      .gui_iit {
        flex: 1;
        text-align: center;
      }
    }
    .gui_a {
      background: #c8161d;
      color: #fff;
    }
    .jbq {
      width: 130px;
      margin: 0 10px; // position: absolute;
      // top: 85px;
      ::v-deep .el-input-number {
        width: 130px;
      }
    }
  }
}

// .gui {
//   display: flex;
//   margin-top: 20px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   .gui_l {
//     margin-top: 25px;
//     margin-right: 20px;
//     width: 40px;
//   }
//   .gui_r {
//     flex: 1;
//     // display: flex;
//     // flex-wrap: wrap;
//     margin-top: 25px;
//     .gui_all {
//       // display: flex;
//       // align-items: center;
//     }
//     .gui_t {
//       margin-bottom: 10px;
//       .coloNum {
//         color: #b42b2a;
//         font-weight: 600;
//       }
//     }
//     .gui_i {
//       display: flex;
//       align-items: center;
//       background: #ededed;
//       border-radius: 10px;
//       font-size: 18px;
//       color: #333333;
//       margin: 0 20px 20px 0;
//       padding: 10px;
//       img {
//         width: 55px;
//         height: 56px;
//         margin-right: 10px;
//         border-radius: 10px;
//       }
//     }
//     .gui_a {
//       background: #ff5654;
//       color: #fff;
//     }
//   }
// }
.addCar {
  background: #d61820;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  padding: 12px 20px;
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
}

.tit1 {
  font-size: 18px;
  font-weight: 600;
  color: #b42b2a;
  margin-right: 10px;
  margin-bottom: 5px;
}

.tit2 {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 5px;
}

.tit3 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 5px;
}

.tit4 {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-right: 10px;
}

.tit5 {
  font-size: 24px;
  font-weight: 600;
  color: #b42b2a;
}

.tit6 {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-left: 30px;
}

.tit7 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.tit8 {
  font-size: 18px;
  font-weight: 400;
  color: #b42b2a;
}

.tit9 {
  font-size: 18px;
  font-weight: 400;
  color: #999999;
}

::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #c01f1f !important;
}

::v-deep .el-breadcrumb {
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400 !important;
}

::v-deep .el-breadcrumb__inner.is-link {
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400 !important;
}

.menuList {
  display: flex;
  margin-top: 20px;
  padding: 0 60px;
  box-sizing: border-box;
  .menu_i {
    margin-right: 70px;
    line-height: 50px;
    cursor: pointer;
  }
  .actMenu {
    border-bottom: 5px solid #b42b2a;
  }
}

.xian {
  height: 5px;
  background: rgba(0, 0, 0, 0.05);
  margin-top: -5px;
}

.allDetail {
  padding: 40px 200px;
  text-align: center; // width: 100%;
  ::v-deep img {
    max-width: 100%;
  }
}

.qzbq {
  font-size: 22px;
  font-weight: 600;
  color: #d61820;
  padding: 0px 5px;
  margin-right: 5px;
}

.fw {
  margin-top: 40px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  .fw_i {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin: 0 20px 0 10px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.jusc {
  justify-content: space-between;
  background: #ea3a3a;
  border-radius: 10px 10px 0px 0px;
  padding: 15px 20px;
  font-size: 28px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffd046;
  img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }
}

.jusc_r {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.jusc_ri {
  color: #ffcc33;
  margin-left: 10px;
}

::v-deep .el-input-number--mini {
  width: 105px !important;
}
</style>
